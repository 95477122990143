import Script from 'next/script';
import { memo } from 'react';

type Props = {
    mapsApiKey: string;
}

const ContactMap = ({ mapsApiKey }: Props) => {
    return (
        <>
            <div id='printoutPanel'></div>

            <div id='myMap' style={{ width: '100%', height: '500px', position: 'relative', zIndex: 0 }}></div>
            <Script id="bing-map">
                {`
                    var infobox
                function loadMapScenario() {
                    var map = new Microsoft.Maps.Map(document.getElementById('myMap'), {
                        mapTypeId: Microsoft.Maps.MapTypeId.aerial,
                        center: new Microsoft.Maps.Location(54.697425940010014, 25.233480078688846),
                        zoom: 13
                    });   

                    infobox = new Microsoft.Maps.Infobox(map.getCenter(), {
                        visible: false
                    });
                    infobox.setMap(map);

                    var pins = new Microsoft.Maps.EntityCollection();
                    
                    var position = new Microsoft.Maps.Location(54.708088066371026, 25.22316174216363);
                    var position2 = new Microsoft.Maps.Location(54.691757917697565, 25.25606716862131);
                   
                    var pin = new Microsoft.Maps.Pushpin(position,
                        { text: 'IDEMUS', title: '„Šiuolaikinė ortopedija”',color:'#5577a0'})
                
                    pin.metadata = { 
                        title: '„Šiuolaikinė ortopedija” Viršuliškių g. 34, Vilnius',
                        description: 'Viršuliškių g. 34, Vilnius'
                    }
                    var pin2 = new Microsoft.Maps.Pushpin(position2,
                        { text: 'IDEMUS', title: '„Šiuolaikinė ortopedija”',color:'#5577a0'})
                
                    pin2.metadata = { 
                        title: '„Šiuolaikinė ortopedija” Vytauto g. 24, Vilnius',
                        description: 'Vytauto g. 24, Vilnius' 
                    }

                    pins.push(pin);
                    pins.push(pin2);

                    Microsoft.Maps.Events.addHandler(pin, 'click', pushpinClicked);
                    Microsoft.Maps.Events.addHandler(pin2, 'click', pushpinClicked);

                    map.entities.push(pins);
                }
                function pushpinClicked(e) {
                    if (e.target.metadata) {
                        infobox.setOptions({
                            location: e.target.getLocation(),
                            title: e.target.metadata.title,
                            description: e.target.metadata.description,
                            visible: true
                        });
                    }
                }
                `}
            </Script>
            <Script src={`https://www.bing.com/api/maps/mapcontrol?key=${mapsApiKey}&callback=loadMapScenario`} ></Script>
        </>
    )
}

export default memo(ContactMap)
