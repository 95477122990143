import { MenuItemType } from '@idcms/store'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Link from 'next/link'
import { useState } from 'react'
import { getTheme } from '../../Theme'
import SideSubmenu from './SideSubmenu'

type Props = {
    toggleSideMenu?: () => void;
    menuItem?: MenuItemType;
    sideMenuItems?: MenuItemType[];
}

const SideMenuItem = ({ sideMenuItems, toggleSideMenu, menuItem }: Props) => {
    const [openSubmenu, setOpenSubmenu] = useState(false)
    const toggleSubmenu = () => {
        !openSubmenu ? setOpenSubmenu(true) : setOpenSubmenu(false)
    }
    const theme = getTheme()

    const hasSubcategories = (sideMenuItems?.filter((mi) => mi.parentId === menuItem?.id).length ?? 0) > 0

    return (
        <>
            {hasSubcategories &&
                <ListItem disablePadding
                    onClick={toggleSubmenu}
                    sx={{ backgroundColor: openSubmenu ? "#F6F6F6" : 'transparent' }}>
                    <ListItemButton disableTouchRipple >
                        <Stack direction={'row'} alignItems={'center'} justifyContent={openSubmenu ? 'space-between' : 'flex-start'} width={'100%'}>
                            {!openSubmenu && <ArrowForwardIosRoundedIcon fontSize='small' sx={{ color: theme.palette.primary.main }} />}
                            <Typography variant='subtitle1' pl={openSubmenu ? 4.5 : 2} lineHeight={1.4} sx={{ color: theme.palette.primary.main, paddingRight: '20px' }}>
                                {menuItem?.name}
                            </Typography>
                            {openSubmenu && <ArrowForwardIosRoundedIcon fontSize='small' sx={{ color: theme.palette.primary.main }} />}
                        </Stack>
                    </ListItemButton>
                </ListItem>}

            {!hasSubcategories &&
                <ListItem disablePadding
                    sx={{ backgroundColor: openSubmenu ? "#F6F6F6" : 'transparent', }}>
                    <ListItemButton disableTouchRipple >
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>

                            <Link onClick={toggleSideMenu} passHref href={`/ortopedijos-gaminiai/${menuItem?.slug?.slug}`}>
                                <Typography variant='subtitle1' pl={4.5} lineHeight={1.4}
                                    sx={{ '&:hover': { textDecoration: 'underline' }, color: theme.palette.primary.main, paddingRight: '20px' }}>
                                    {menuItem?.name}
                                </Typography>
                            </Link>
                        </Stack>
                    </ListItemButton>
                </ListItem>}

            {openSubmenu &&
                <SideSubmenu sideMenuItems={sideMenuItems} toggleSubmenu={toggleSubmenu} toggleSideMenu={toggleSideMenu}
                    openSubmenu={openSubmenu} menuItem={menuItem} />}
        </>
    )
}

export default SideMenuItem