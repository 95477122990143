import { PostType } from '@idcms/store';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import AliceCarousel from 'react-alice-carousel';
import SlideButtonBack from '../../icons/SlideButtonBack';
import SlideButtonNext from '../../icons/SlideButtonNext';
import BlogPostCard from '../../blog/BlogPostCard';
import CustomArrowButton from '../../shared/CustomArrowButton';
import SectionTitle from './SectionTitle';
import "react-alice-carousel/lib/alice-carousel.css";

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 2, },
    1224: { items: 3, itemsFit: 'contain' },
};

type Props = {
    posts?: PostType[];
}

const BlogSection = ({ posts }: Props) => {
    const { t } = useTranslation('home')

    const items = posts?.map((post) => {
        return (
            <Stack mx={'auto'} px={1} key={post.id}>
                <BlogPostCard post={post} />
            </Stack>
        )
    })

    return (
        <Stack sx={{ backgroundColor: '#F8F8F8' }} mt={0} pt={5} pb={3}>
            <Stack direction={'row'} justifyContent={'center'} width={'100%'} mx={'auto'}>
                <Stack maxWidth={'xl'} width={'100%'} px={3}>
                    <SectionTitle title={t('blog.title').toUpperCase()} />
                    <Grid container pt={0} px={{ lg: 5, md: 5, sm: 5, xs: 1 }} display={'flex'}
                        flexDirection={'row'} position={'relative'}>
                        <AliceCarousel
                            items={items}
                            mouseTracking
                            disableDotsControls
                            responsive={responsive}
                            controlsStrategy="responsive"
                            infinite
                            keyboardNavigation
                            renderPrevButton={() =>
                                <Stack position={'absolute'} left={-10} top={'calc(50% - 55px)'}>
                                    <Stack position={'absolute'} sx={{ cursor: 'pointer' }} left={-24}
                                        display={{ sm: 'none', xs: 'flex' }}>
                                        <SlideButtonBack fillColor='none' color='#192357' />
                                    </Stack>
                                    <Stack position={'absolute'} sx={{ cursor: 'pointer' }}
                                        display={{ sm: 'flex', xs: 'none' }} left={-40}>
                                        <SlideButtonBack fillColor='#FFFFFF' color='#192357' />
                                    </Stack>
                                </Stack>}
                            renderNextButton={() =>
                                <Stack position={'absolute'} right={-10} top={'calc(50% - 55px)'}>
                                    <Stack position={'absolute'} sx={{ cursor: 'pointer' }} right={-24}
                                        display={{ sm: 'none', xs: 'flex' }}>
                                        <SlideButtonNext fillColor='none' color='#192357' />
                                    </Stack>
                                    <Stack position={'absolute'} sx={{ cursor: 'pointer' }}
                                        display={{ sm: 'flex', xs: 'none' }} right={-40}>
                                        <SlideButtonNext fillColor='#FFFFFF' color='#192357' />
                                    </Stack>
                                </Stack>}
                        />
                    </Grid>
                </Stack>
            </Stack>
            <Stack maxWidth={'180px'} width={'100%'} mx={'auto'} mt={3}>
                <Link passHref href={'/informacija/naudinga-zinoti'} className='removeLinkUnderline'>
                    <CustomArrowButton text={t('shared.viewAllPosts')} />
                </Link>
            </Stack>
        </Stack>
    )
}

export default BlogSection