import Typography from '@mui/material/Typography'
import React from 'react'

type Props = {
    title: string;
}

const WorkDayComponent = ({ title }: Props) => {
    return (
        <Typography
            variant='subtitle1' lineHeight={1} component={'p'}
            sx={{
                backgroundColor: '#192357', p: .8, borderRadius: '5px', color: '#fff',
                boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
            }}>
            {title}
        </Typography>
    )
}

export default WorkDayComponent