import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'next-i18next';
import AliceCarousel from 'react-alice-carousel';
import SlideButtonBack from '../../icons/SlideButtonBack';
import SlideButtonNext from '../../icons/SlideButtonNext';
import ReviewCard from '../../reviews/ReviewCard';
import SectionTitle from './SectionTitle';
import { ProductReviewType } from '@idcms/store';

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    750: { items: 3 },
    1024: { items: 4 },
    1224: { items: 4, itemsFit: 'contain' },
};


type Props = {
    reviews?: ProductReviewType[];
}
const CustomerReviewsSection = ({ reviews }: Props) => {
    const { t } = useTranslation('home')

    const items = reviews?.map((review) =>
        <Stack mx={'auto'} px={1} key={review.id}>
            <ReviewCard review={review} />
        </Stack>
    )

    return (
        <>

            {reviews &&
                <Stack sx={{ backgroundColor: '#FFF' }} mt={0} pt={5} pb={9}>
                    <Stack direction={'row'} justifyContent={'center'}
                        width={'100%'} mx={'auto'}>
                        <Stack maxWidth={'xl'} width={'100%'} px={3}>
                            <SectionTitle title={t('customerReviews.title').toUpperCase()} />
                            <Grid container pt={0} px={{ lg: 5, md: 5, sm: 5, xs: 1 }} display={'flex'}
                                flexDirection={'row'} position={'relative'}>
                                <AliceCarousel
                                    mouseTracking
                                    disableDotsControls
                                    items={items}
                                    responsive={responsive}
                                    controlsStrategy="responsive"
                                    infinite
                                    keyboardNavigation
                                    renderPrevButton={() =>
                                        <Stack position={'absolute'} left={-10} top={'calc(50% - 55px)'}>
                                            <Stack position={'absolute'} sx={{ cursor: 'pointer' }} left={-24}
                                                display={{ sm: 'none', xs: 'flex' }}>
                                                <SlideButtonBack fillColor='none' color='#192357' />
                                            </Stack>
                                            <Stack position={'absolute'} sx={{ cursor: 'pointer' }}
                                                display={{ sm: 'flex', xs: 'none' }} left={-40}>
                                                <SlideButtonBack fillColor='#FFFFFF' color='#192357' />
                                            </Stack>
                                        </Stack>
                                    }
                                    renderNextButton={() =>
                                        <Stack position={'absolute'} right={-10} top={'calc(50% - 55px)'}>
                                            <Stack position={'absolute'} sx={{ cursor: 'pointer' }} right={-24}
                                                display={{ sm: 'none', xs: 'flex' }}>
                                                <SlideButtonNext fillColor='none' color='#192357' />
                                            </Stack>
                                            <Stack position={'absolute'} sx={{ cursor: 'pointer' }}
                                                display={{ sm: 'flex', xs: 'none' }} right={-40}>
                                                <SlideButtonNext fillColor='#FFFFFF' color='#192357' />
                                            </Stack>
                                        </Stack>
                                    }
                                />
                            </Grid>

                        </Stack>
                    </Stack>
                </Stack>}
        </>

    )
}

export default CustomerReviewsSection