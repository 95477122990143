import { useMutation } from '@apollo/client'
import { yupResolver } from "@hookform/resolvers/yup"
import { ProductReviewMutation, ProductReviewType, ProductReviewVariables } from '@idcms/store'
import LoadingButton from '@mui/lab/LoadingButton'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from "yup"
import SlideButtonNext from '../../icons/SlideButtonNext'
import SaveMessage from '../../address/SaveMessage'
import ErrorBox from '../../shared/errors/ErrorBox'
import { useSession } from 'next-auth/react'
import { useState } from 'react'
import { DialogComponent } from '../../shared/DialogComponent'
import { LoginForm } from '../../shared/auth/LoginForm'
import { styled } from '@mui/material/styles'
import Rating from '@mui/material/Rating'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'

const WriteReviewComponent = () => {
    const { t, i18n } = useTranslation('review')
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [openDialog, setOpenDialog] = useState(false)
    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#fff',
        },
        '& .MuiRating-iconHover': {
            color: '#fff',
        },
        '& .MuiSvgIcon-root': {
            fill: '#fff !important',
        },
    });

    const schema = yup.object({
        rating: yup.number().required(t('form.required', { ns: 'common' }) ?? ''),
        review: yup.string().required(t('form.required', { ns: 'common' }) ?? '')
    })

    const { control, handleSubmit, register, formState: { errors }, reset } = useForm<ProductReviewType>({
        defaultValues: { productId: 0, rating: 5 },
        resolver: yupResolver(schema as any)
    })

    const [save, { data, loading, error }] = useMutation<ProductReviewType, ProductReviewVariables>(ProductReviewMutation(), {
        errorPolicy: 'all',
        refetchQueries: ['ProductReviews'],
        context: {
            headers: {
                "Content-Language": i18n.language
            }
        }
    })

    const { status } = useSession()

    const signedIn = status === 'authenticated'

    const onSubmit: SubmitHandler<ProductReviewType> = async (data) => {
        setErrorMessage(undefined)
        setOpenDialog(false)
        if (signedIn) {
            await save({
                variables: { data }
            })
            reset()
        } else {
            setErrorMessage(t('writeReview.notLoggedIn'))
            setTimeout(() => {
                setOpenDialog(true)
            }, 1000)
        }
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {<Grid container sx={{ backgroundColor: 'primary.main' }}
                    px={{ lg: 5, md: 5, sm: 3, xs: 3 }} py={6} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                    <Grid item display={'flex'} flexDirection={'column'} lg={4} md={6} sm={6} xs={12}>
                        <Typography fontSize={'20px'} fontWeight={500} color={'#fff'} mb={3}>
                            {t('writeReview.title').toUpperCase()}
                        </Typography>
                        <TextField sx={{ mb: 1 }} required
                            placeholder={t('writeReview.reviewText') + '*' ?? ''} size='small'
                            multiline fullWidth rows={9}
                            {...register("review")}
                            error={!!errors.review?.message}
                        // helperText={errors.review?.message}
                        />
                        {errors.review?.message && <ErrorBox errors={errors.review?.message} />}

                    </Grid>

                    <Grid item lg={4} md={6} sm={6} xs={12} px={{ lg: 10, md: 10, sm: 5, xs: 0 }}
                        display={'flex'} flexDirection={'column'} mt={4}>
                        <Typography fontSize={'14px'} fontWeight={200} lineHeight={'16px'} color={'#fff'}>
                            {t('writeReview.starRating')}
                        </Typography>
                        <Stack direction={'row'} spacing={3} my={2}>
                            <Controller
                                control={control}
                                name={'rating'}
                                render={({ field: { value, onChange } }) => (
                                    <FormControlLabel
                                        control={
                                            <StyledRating
                                                name="rating"
                                                sx={{ color: '#fff', fontSize: '34px', pb: 1, ml: 1 }}
                                                value={value}
                                                onChange={(_e, value) => {
                                                    onChange(value)
                                                }}
                                            />
                                        }
                                        label=""
                                    />
                                )} />
                        </Stack>
                        <Stack mt={{ lg: 5, md: 5, sm: 5, xs: 2 }} maxWidth={300} minWidth={150}>
                            <LoadingButton variant='contained' fullWidth type='submit'
                                color={'info'}
                                sx={{ display: 'flex', justifyContent: 'flex-start', height: '43px', marginBottom: 1, textTransform: 'none', backgroundColor: '#fff', minWidth: '180px' }}
                                endIcon={!loading ?
                                    <Stack sx={{ position: 'absolute', left: 'calc(100% - 49px)', top: -3 }}>
                                        <SlideButtonNext />
                                    </Stack>
                                    :
                                    <Stack sx={{ position: 'absolute', left: 'calc(100% - 35px)', top: 11 }}>
                                        <CircularProgress size={20} />
                                    </Stack>
                                }>
                                <Typography fontSize={'16px'} fontWeight={500} color={'primary.main'} textAlign={'left'}> {t('writeReview.postReview').toUpperCase()}</Typography>

                            </LoadingButton>
                            {errorMessage && <ErrorBox errors={errorMessage} />}
                            {error && <ErrorBox errors={error} />}
                            {errors.rating?.message && <ErrorBox errors={errors.rating?.message} />}
                            {data && !error && <SaveMessage text={`${t('writeReview.reviewSentSuccessfully')}` ?? ''} marginTop={'0px'} marginBottom={'0px'} />}
                        </Stack>
                    </Grid>
                </Grid>}
            </form >


            <DialogComponent maxWidth={450} open={openDialog} handleOpen={() => setOpenDialog(!openDialog)} title={`${t('writeReview.loginToReview')}`}>
                <LoginForm toggleDialog={() => setOpenDialog(!openDialog)} />
            </DialogComponent>
        </>
    )
}

export default WriteReviewComponent