import { BannerType, MenuItemType } from '@idcms/store';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Image from "next/legacy/image";
import { useState } from 'react';
import SlideButtonBack from '../../icons/SlideButtonBack';
import Loading from '../../shared/loading/Loading';
import Box from '@mui/material/Box';
import Link from 'next/link';
import { getTheme } from '../../layout/Theme';
import { useTranslation } from 'next-i18next';
import SideMenuItem from '../../layout/menus/product-menu/SideMenuItem';

type Props = {
    banners?: BannerType[];
    sideMenuItems?: MenuItemType[]
}

const BannerComponent = ({ banners, sideMenuItems }: Props) => {
    const heroBanners = banners ?? []
    const [activeSlide, setActiveSlide] = useState<number>(0)
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    function handleTouchStart(e: any) {
        setTouchStart(e.targetTouches[0].clientX);
    }
    function handleTouchMove(e: any) {
        setTouchEnd(e.targetTouches[0].clientX);
    }
    const toNextSlide = () => {
        if (heroBanners) {
            const max = heroBanners && heroBanners.length - 1
            setActiveSlide((activeSlide + 1) > max ? 0 : activeSlide + 1)
        }
    }
    const toPreviousSlide = () => {
        if (heroBanners) {
            const max = heroBanners && heroBanners.length - 1
            setActiveSlide((activeSlide - 1) < 0 ? max : activeSlide - 1)
        }
    }

    function handleTouchEnd() {
        if (heroBanners)
            if (touchStart - touchEnd > 100) {
                toNextSlide()
            }
        if (touchStart - touchEnd < -100) {
            toPreviousSlide()
        }
    }

    const slug = heroBanners[activeSlide]?.translation?.media?.lg?.path;
    const title = heroBanners[activeSlide]?.translation?.title;
    const subtitle = heroBanners[activeSlide]?.translation?.subtitle
    const button = heroBanners[activeSlide]?.translation?.button
    const url = heroBanners[activeSlide]?.translation?.url

    const theme = getTheme()

    const { t } = useTranslation()

    const renderCategories = sideMenuItems?.filter((mi) => mi.parentId === null)
        ?.map((mi) =>
            <SideMenuItem menuItem={mi} sideMenuItems={sideMenuItems} key={mi.id} />
        )

    return (
        <Stack width={'100vw'} position={'relative'} overflow={'hidden'} direction={'row'}
            minHeight={{ xl: '700px', lg: '700px', md: '700px', sm: '700px', xs: '450px' }}
            height={{ xl: '700px', lg: '700px', md: '700px', sm: '700px', xs: '450px' }}>
            <Stack sx={{ width: '400px', height: '100%', zIndex: 9, display: { lg: 'flex', xs: 'none', boxShadow: '0px 3px 8px #19235726', } }}>
                <Stack
                    className={'scrollBar'}
                    sx={{
                        position: "relative", width: '100%', height: '100%',
                        verflowY: 'scroll',
                        top: '0px', zIndex: 9999,
                        backgroundColor: '#fff',
                    }}>
                    <Stack pt={5} sx={{ overflowY: 'scroll', height: 'calc(100vh - 150px)', pb: 5 }} >
                        {renderCategories}
                        <Stack
                            sx={{
                                pl: 9, color: theme.palette.secondary.main,
                                cursor: 'pointer', wordBreak: 'break-word',
                                mt: 1, mb: 1,
                                '&:hover': { textDecoration: 'underline' }, backgroundColor: '#fff'
                            }}>
                            <Link passHref href={`/ortopedijos-gaminiai`}>
                                <Typography variant='subtitle1' fontWeight={500} lineHeight={1.4} sx={{ color: theme.palette.secondary.main, paddingRight: '20px' }}>
                                    {(t('actions.seeAllProducts'))}
                                </Typography>
                            </Link>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {heroBanners && heroBanners.length > 1 &&
                <>
                    <Stack sx={{ justifyContent: 'space-between' }} >
                        <Button disableTouchRipple onClick={toPreviousSlide} aria-label="previous slide" sx={{
                            display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' },
                            zIndex: 4, cursor: 'pointer', "&:hover": { backgroundColor: "transparent" },
                            position: 'absolute', left: '-15px',
                            top: { sm: '272px', xs: '192px' }
                        }}>
                            <SlideButtonBack fillColor='#fff' color='#192357' scale={.7} />
                        </Button>
                        <Button disableTouchRipple onClick={toPreviousSlide} aria-label="previous slide" sx={{
                            display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                            zIndex: 4, cursor: 'pointer', "&:hover": { backgroundColor: "transparent" },
                            position: 'absolute', left: { lg: '400px', sm: 0 },
                            top: { xl: '305px', lg: '305px', md: '315px' }
                        }}>
                            <SlideButtonBack fillColor='#FFF' color='#192357' scale={1} />
                        </Button>
                    </Stack>
                    <Stack sx={{ justifyContent: 'space-between' }}>
                        <Button disableTouchRipple onClick={toNextSlide} aria-label="next slide" sx={{
                            display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' },
                            zIndex: 4, cursor: 'pointer', "&:hover": { backgroundColor: "transparent" },
                            transform: 'rotate(180deg)', position: 'absolute', right: '-15px',
                            top: { sm: '268px', xs: '188px' }
                        }}>
                            <SlideButtonBack fillColor='#fff' color='#192357' scale={.7} />
                        </Button>
                        <Button disableTouchRipple onClick={toNextSlide} aria-label="next slide" sx={{
                            display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
                            zIndex: 4, cursor: 'pointer', "&:hover": { backgroundColor: "transparent" },
                            transform: 'rotate(180deg)', position: 'absolute', right: '0px',
                            top: { xl: '300px', lg: '300px', md: '310px' }
                        }}>
                            <SlideButtonBack fillColor='#FFF' color='#192357' scale={1} />
                        </Button>
                    </Stack>
                </>}

            {!slug && <Box maxWidth={'100vw'} width={'100%'} sx={{ zIndex: 9, position: 'fixed', left: -20, mt: '1px' }}>
                <Loading />
            </Box>}

            {slug && <Image src={slug ?? ''}
                layout={'fill'}
                objectFit={'cover'}
                objectPosition={'center'}
                alt={title}
                priority
                onTouchStart={(e) => { handleTouchStart(e) }}
                onTouchEnd={handleTouchEnd}
                onTouchMove={(e) => { handleTouchMove(e) }}
            />}
            <Stack sx={{ textShadow: '4px 4px 10px #000', mx: 'auto', my: 'auto', position: 'relative', direction: 'row' }}>

                <Stack px={4}>
                    <Typography variant={'h2'} component={'h1'} fontWeight={600} textAlign={'center'} color={'#fff'}>
                        {title}
                    </Typography>
                    {subtitle &&
                        <Typography textAlign={'center'} color={'#fff'} pt={1} variant='subtitle1' component={'h2'}>
                            {subtitle}
                        </Typography>}
                    {button &&
                        <Button href={url} variant={'contained'}
                            sx={{ mt: 4, px: 6, py: 1, maxWidth: 'max-content', mx: 'auto' }}>
                            {button}
                        </Button>}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default BannerComponent
