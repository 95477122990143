import { ProductType } from '@idcms/store';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import AliceCarousel from 'react-alice-carousel';
import SlideButtonBack from '../../icons/SlideButtonBack';
import SlideButtonNext from '../../icons/SlideButtonNext';
import ProductCard from '../../products/ProductCard';
import CustomArrowButton from '../../shared/CustomArrowButton';
import SectionTitle from './SectionTitle';

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    750: { items: 3 },
    1024: { items: 4 },
    1224: { items: 5, itemsFit: 'contain' },
};

type Props = {
    newProducts: ProductType[]
}
const NewProductsSection = ({ newProducts }: Props) => {
    const { t } = useTranslation('home')

    const renderProductCards = newProducts.map((product) => {
        return (
            <Stack px={1} pb={2} key={product.id}>
                <ProductCard product={product} />
            </Stack>
        )
    })

    return (
        <Stack sx={{ backgroundColor: '#EFEFEF' }} mt={0} pt={5} pb={3}>
            <Stack direction={'row'} justifyContent={'center'} maxWidth={'xl'}
                width={'100%'} mx={'auto'}>
                <Stack width={'100%'} px={3}>
                    <SectionTitle title={t('newProducts.title').toUpperCase()} />
                    <Stack pt={2} px={2}>
                        <AliceCarousel
                            mouseTracking
                            disableDotsControls
                            items={renderProductCards}
                            responsive={responsive}
                            controlsStrategy="responsive"
                            infinite
                            keyboardNavigation
                            renderPrevButton={() =>
                                <Stack position={'absolute'} left={-10} top={'calc(50% - 55px)'}>
                                    <Stack position={'absolute'} sx={{ cursor: 'pointer' }} left={-30}
                                        display={{ sm: 'none', xs: 'flex' }}>
                                        <SlideButtonBack fillColor='none' color='#192357' />
                                    </Stack>
                                    <Stack position={'absolute'} sx={{ cursor: 'pointer' }}
                                        display={{ sm: 'flex', xs: 'none' }} left={{ sm: -30 }}>
                                        <SlideButtonBack fillColor='#FFFFFF' scale={.8} color='#192357' />
                                    </Stack>
                                </Stack>
                            }
                            renderNextButton={() =>
                                <Stack position={'absolute'} right={-10} top={'calc(50% - 55px)'}>
                                    <Stack position={'absolute'} sx={{ cursor: 'pointer' }} right={-30}
                                        display={{ sm: 'none', xs: 'flex' }}>
                                        <SlideButtonNext fillColor='none' color='#192357' />
                                    </Stack>
                                    <Stack position={'absolute'} sx={{ cursor: 'pointer' }}
                                        display={{ sm: 'flex', xs: 'none' }} right={{ sm: -30 }}>
                                        <SlideButtonNext fillColor='#FFFFFF' scale={.8} color='#192357' />
                                    </Stack>
                                </Stack>
                            }
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Stack maxWidth={'180px'} width={'100%'} mx={'auto'}
                mt={{ lg: 4, md: 4, sm: 2, xs: 2 }}>
                <Link passHref href={'/ortopedijos-gaminiai'} className='removeLinkUnderline'>
                    <CustomArrowButton text={t('shared.viewAllProducts')} />
                </Link>
            </Stack>
        </Stack>
    )
}

export default NewProductsSection