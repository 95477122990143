import Typography from '@mui/material/Typography';
import React from 'react'
import { getTheme } from '../layout/Theme';

type Props = {
    text?: string;
    marginTop?: string;
    marginBottom?: string;
}

const SaveMessage = ({ text, marginTop, marginBottom }: Props) => {
    const theme = getTheme()
    return (
        <Typography sx={{
            color: theme.palette.secondary.main,
            marginTop: marginTop ?? '-40px', marginBottom: marginBottom ?? '30px', paddingTop: '5px',
            fontSize: '0.8rem'
        }}>
            {text ? text : 'Your profile information was successfully updated!'}
        </Typography>
    )
}

export default SaveMessage