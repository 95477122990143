import React from "react";
import style from "../loading/loading.module.scss";

const Loading = () => {
  return (
    <div className={style.progress} style={{
      position: 'relative'
    }}>
      <div className={style.progressValue} style={{
        position: 'absolute'
      }}></div>
    </div>
  )
}

export default Loading
