import { BannerType, MenuItemType, PostType, ProductReviewType, ProductType } from '@idcms/store';
import BannerComponent from './BannerComponent';
import BlogSection from './BlogSection';
import ContactsSectionComponent from './ContactsSectionComponent';
import CustomerReviewsSection from './CustomerReviewsSection';
import NewProductsSection from './NewProductsSection';
import WriteReviewComponent from './WriteReviewComponent';

type Props = {
  newProducts?: ProductType[]
  mapsApiKey: string;
  posts?: PostType[];
  banners?: BannerType[];
  reviews?: ProductReviewType[];
  sideMenuItems?: MenuItemType[]
}

const HomeComponent = ({ reviews, banners, newProducts, posts, mapsApiKey, sideMenuItems }: Props) => {
  const heroBanners = banners?.filter(((b: BannerType) => b.type === 3 && b.translation))

  return (
    <>
      <BannerComponent banners={heroBanners} sideMenuItems={sideMenuItems} />
      {newProducts &&
        <NewProductsSection newProducts={newProducts} />}
      <BlogSection posts={posts} />
      <CustomerReviewsSection reviews={reviews} />
      <WriteReviewComponent />
      <ContactsSectionComponent mapsApiKey={mapsApiKey} />
    </>
  )
}

export default HomeComponent
