import { ProductReviewType } from '@idcms/store'
import Rating from '@mui/material/Rating'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Image from "next/legacy/image"

type Props = {
    review: ProductReviewType
}

const ReviewCard = ({ review }: Props) => {
    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#192357',
        },
        '& .MuiRating-iconHover': {
            color: '#192357',
        },
        '& .MuiSvgIcon-root': {
            fill: '#192357 !important',
        },
    });
    return (
        <Stack p={{ lg: 4, md: 4, sm: 3, xs: 3 }} mb={2} mt={3}
            sx={{ backgroundColor: '#fff', boxShadow: '0px 3px 8px #19235726', borderRadius: 2 }}>
            <Stack spacing={2} alignItems={'center'}
                direction={{ lg: 'row', md: 'row', sm: 'row', xs: 'row' }}>

                <Stack position={'relative'} minWidth={'40px'} minHeight={'40px'}
                    borderRadius={'50%'} overflow={'hidden'}>
                    <Image
                        style={{ opacity: .65 }}
                        objectFit='cover'
                        layout='responsive'
                        width={60}
                        height={60}
                        alt=""
                        src={'/assets/images/check-icon.png'}>
                    </Image>
                </Stack>

                <Stack spacing={1}>
                    <Typography variant='subtitle1'
                        fontWeight={500}
                        mt={0} color={'primary'}>
                        {review.firstName ?? review.reviewingCustomer?.firstName} {review.lastName ?? review.reviewingCustomer?.lastName}
                    </Typography>
                    <StyledRating
                        sx={{ fontSize: { lg: '24px', md: '24px', sm: '24px', xs: '22px' } }}
                        defaultValue={review.rating}
                        readOnly
                    />
                </Stack>
            </Stack>
            <Stack pb={1} mt={1}>
                <Typography variant='body1' fontWeight={300}
                    mt={1} color={'primary'} textAlign={'justify'}>
                    {review.review}
                </Typography>
            </Stack>
        </Stack>
    )
}

export default ReviewCard