import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactNode } from "react";
import { getTheme } from '../layout/Theme';

type Props = {
    open: boolean;
    handleOpen?: () => void;
    children: ReactNode;
    title: string;
    subtitle?: string;
    maxWidth?: string | number;
}

export const DialogComponent = ({ maxWidth, open, handleOpen, children, title, subtitle }: Props) => {
    const theme = getTheme()
    return (
        <Modal sx={{ backgroundColor: theme.palette.primary.main + 99 }}
            open={open}>
            <Stack justifyContent={'center'} alignItems={'center'} alignContent={'center'} height={'100%'}>

                <Stack width={'100%'} maxWidth={maxWidth ? maxWidth : 'sm'} sx={{ backgroundColor: '#fff', borderRadius: '10px' }} p={6}>
                    <Stack direction={"column"} justifyContent={'flex-start'}>
                        <Stack direction={'row'} alignContent={'center'} justifyContent={'space-between'}>
                            <Typography fontWeight={700} variant='h5' color={theme.palette.primary.main}>{title}</Typography>
                            <Stack onClick={handleOpen} sx={{ top: 5, cursor: 'pointer', position: 'relative' }}>
                                <CloseRoundedIcon sx={{ color: theme.palette.primary.main }} />
                            </Stack>
                        </Stack>
                        <Typography fontWeight={400} lineHeight={1.2} variant="body2" color={theme.palette.primary.main} pt={.5} pb={subtitle ? 1 : 2}>
                            {subtitle}
                        </Typography>
                    </Stack>
                    {children}
                </Stack>
            </Stack>
        </Modal>
    )
}