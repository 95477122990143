import { MenuItemType } from '@idcms/store';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { getTheme } from '../../Theme';
import SideSubmenuItem from './SideSubmenuItem';

type Props = {
  title?: string;
  listItems?: any,
  openSubmenu: boolean;
  toggleSubmenu: () => void;
  toggleSideMenu?: () => void;
  sideMenuItems?: MenuItemType[];
  menuItem?: MenuItemType;
}

const SideSubmenu = ({ sideMenuItems, menuItem, toggleSubmenu, toggleSideMenu }: Props) => {
  const theme = getTheme()
  const { t } = useTranslation()


  const renderSubcategories = sideMenuItems?.filter((smi) => smi.parentId === menuItem?.id)
    ?.map((smi) =>
      <SideSubmenuItem menuItem={menuItem} sideMenuItems={sideMenuItems} toggleSideMenu={toggleSideMenu} submenuItem={smi} key={smi.id} />
    )

  return (
    <ClickAwayListener onClickAway={toggleSubmenu}>
      <Stack sx={{
        borderLeft: '1px solid #EFEFEF',
        position: "absolute",
        left: 400,
        verflowY: 'scroll',
        width: 400,
        height: '100%', top: '0px',

      }}>
        <Stack
          spacing={2}
          sx={{
            backgroundColor: '#fff',
            py: 4,
            overflowY: 'scroll', height: toggleSideMenu ? 'calc(100vh - 150px)' : '700px', pb: 5
          }}>
          <Link onClick={toggleSideMenu} passHref href={`/ortopedijos-gaminiai/${menuItem?.slug?.slug}`}>
            <Stack
              sx={{
                paddingLeft: "35px", color: theme.palette.secondary.main,
                cursor: 'pointer', wordBreak: 'break-word',
                mt: 2,
                '&:hover': { textDecoration: 'underline' }, backgroundColor: '#fff'
              }}>
              <Typography variant='subtitle1' fontWeight={500} lineHeight={1.4}
                sx={{ color: theme.palette.secondary.main, paddingRight: '20px' }}>
                {(t('actions.seeCategory'))}
              </Typography>
            </Stack>
          </Link>
          {renderSubcategories}
        </Stack>
      </Stack>
    </ClickAwayListener >
  )
}

export default SideSubmenu
