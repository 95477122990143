import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import WorkDayComponent from '../../shared/WorkDayComponent'
import BingMap from '../../shared/maps/BingMap'
import SectionTitle from './SectionTitle'
import Link from 'next/link'

type Props = {
    mapsApiKey: string;
}

const ContactsSectionComponent = ({ mapsApiKey }: Props) => {
    const { t } = useTranslation('contacts')
    return (
        <Stack sx={{ backgroundColor: '#FFF' }} mt={0} pt={5} pb={3}>
            <Stack direction={'row'} justifyContent={'center'} maxWidth={'xl'} width={'100%'} mx={'auto'}>
                <Stack maxWidth={'xl'} width={'100%'} px={3}>
                    <SectionTitle title={t('contacts.title').toUpperCase()} />

                    <Grid container pt={2} spacing={2} display={'flex'} flexDirection={'row'} mb={5} mt={2}>
                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} display={'flex'} flexDirection={'column'}
                            justifyContent={'space-between'} pr={{ xl: 2, lg: 2, md: 2, sm: 0, xs: 0 }} mb={5}>

                            <Stack width={'100%'} justifyContent={'center'}>
                                <Stack direction={{ lg: 'row', md: 'row', sm: 'row', xs: 'column' }} spacing={8}
                                    justifyContent={{ xs: 'space-around', sm: 'space-around', }} alignItems={{ xs: 'center', sm: 'flex-start' }}>
                                    <Stack width={'100%'} maxWidth={270}>
                                        <Typography fontSize={"20px"} fontWeight={500} color={"primary.main"}
                                            mb={2} sx={{
                                                borderBottom: '2px solid #192357',
                                                padding: '0 0 10px',
                                                maxWidth: '270px',
                                                width: '100%'
                                            }}>
                                            Viršuliškių g. 34, Vilnius
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"}>
                                            „Šiuolaikinė ortopedija”
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"}>
                                            {t('contacts.phone')}: <Link className='underlineOnHover' href={`tel:${'+37061233888'}`}>+370 612 33888</Link>
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"}>
                                            {t('contacts.email')}: <Link className='underlineOnHover' href={`mailto:${'virsuliskiu34@idemus.lt'}`}>virsuliskiu34@idemus.lt</Link>
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={500} lineHeight={'20px'} color={"primary.main"} mt={2}>
                                            {t('contacts.openingHours')}
                                        </Typography>
                                        <Stack direction={'row'} spacing={.7} alignItems={'center'} mb={2} pt={1}>
                                            <WorkDayComponent title={t('contacts.monday')} />
                                            <WorkDayComponent title={t('contacts.tuesday')} />
                                            <WorkDayComponent title={t('contacts.wednesday')} />
                                            <WorkDayComponent title={t('contacts.thursday')} />
                                            <WorkDayComponent title={t('contacts.friday')} />
                                            <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"} pl={1}>
                                                9:00 - 18:00
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack width={'100%'} maxWidth={270}>
                                        <Typography fontSize={"20px"} fontWeight={500} color={"primary.main"}
                                            mb={2} sx={{
                                                borderBottom: '2px solid #192357',
                                                padding: '0 0 10px',
                                                maxWidth: '270px',
                                                width: '100%'
                                            }}>
                                            Vytauto g. 24, Vilnius
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"}>
                                            „Šiuolaikinė ortopedija”
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"}>
                                            {t('contacts.phone')}: <Link className='underlineOnHover' href={`tel:${'+37061032967'}`}>+370 610 32967</Link>
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"}>
                                            {t('contacts.email')}: <Link className='underlineOnHover' href={`mailto:${'vytauto24@idemus.lt'}`}>vytauto24@idemus.lt</Link>
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={500} lineHeight={'20px'} color={"primary.main"} mt={2}>
                                            {t('contacts.openingHours')}:
                                        </Typography>
                                        <Stack direction={'row'} spacing={.7} alignItems={'center'} pt={1}>
                                            <WorkDayComponent title={t('contacts.monday')} />
                                            <WorkDayComponent title={t('contacts.tuesday')} />
                                            <WorkDayComponent title={t('contacts.wednesday')} />
                                            <WorkDayComponent title={t('contacts.thursday')} />
                                            <WorkDayComponent title={t('contacts.friday')} />
                                            <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"} pl={1}>
                                                9:00 - 17:00
                                            </Typography>
                                        </Stack>
                                        <Stack direction={'row'} spacing={1} mt={1} alignItems={'center'} mb={2}>
                                            <WorkDayComponent title={t('contacts.saturday')} />
                                            <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"} pl={1}>
                                                10:00 - 15:00
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>

                                <Stack direction={{ lg: 'row', md: 'row', sm: 'row', xs: 'column' }} spacing={8} pt={8} pb={6}
                                    justifyContent={{ xs: 'space-around', sm: 'space-around', }} alignItems={{ xs: 'center', sm: 'flex-start' }}>
                                    <Stack width={'100%'} maxWidth={270}>
                                        <Typography fontSize={"20px"} fontWeight={500} color={"primary.main"}
                                            mb={2} sx={{
                                                borderBottom: '2px solid #192357',
                                                padding: '0 0 10px',
                                                maxWidth: '270px',
                                                width: '100%'
                                            }}>
                                            {t('contacts.eShop')}
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"}>
                                            {t('contacts.phone')}: <Link className='underlineOnHover' href={`tel:${'+37065902035'}`}>+370 659 02035</Link>
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"}>
                                            {t('contacts.email')}: <Link className='underlineOnHover' href={`mailto:${'perku@idemus.lt'}`}>perku@idemus.lt</Link>
                                        </Typography>
                                    </Stack>
                                    <Stack width={'100%'} maxWidth={270}>
                                        <Typography fontSize={"20px"} fontWeight={500} color={"primary.main"}
                                            mb={2} sx={{
                                                borderBottom: '2px solid #192357',
                                                padding: '0 0 10px',
                                                maxWidth: '270px',
                                                width: '100%'
                                            }}>
                                            {t('contacts.info')}
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"}>
                                            {t('contacts.phone')}: <Link className='underlineOnHover' href={`tel:${'+37061233888'}`}>+370 612 33888</Link>
                                        </Typography>
                                        <Typography fontSize={"16px"} fontWeight={300} lineHeight={'20px'} color={"primary.main"}>
                                            {t('contacts.email')}: <Link className='underlineOnHover' href={`mailto:${'info@idemus.lt'}`}>info@idemus.lt</Link>
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} height={{ md: '500px', sm: '400px', xs: '350px' }} overflow={'hidden'}>
                            <BingMap mapsApiKey={mapsApiKey} />
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ContactsSectionComponent