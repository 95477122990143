import { MenuItemType } from '@idcms/store';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import { getTheme } from '../../Theme';

type Props = {
    toggleSideMenu?: () => void;
    menuItem?: MenuItemType;
    submenuItem?: MenuItemType;
    sideMenuItems?: MenuItemType[];
}

const SideSubmenuItem = ({ menuItem, sideMenuItems, toggleSideMenu, submenuItem }: Props) => {
    const handleLinkClick = () => {
        toggleSideMenu && toggleSideMenu()
    }
    const theme = getTheme()

    const renderSidemenuItems = sideMenuItems
        ?.filter((smsi) => smsi.parentId === submenuItem?.id)
        ?.map((smsi) => (
            <Stack key={smsi.id} sx={{
                py: .25, color: '#192357', backgroundColor: '#fff',
                '&:hover': { backgroundColor: '#F6F6F6' }, cursor: 'pointer'
            }}>
                <Link passHref href={`/ortopedijos-gaminiai/${menuItem?.slug?.slug}/${submenuItem?.slug?.slug}/${smsi.slug?.slug ?? ''}`}>
                    <Stack alignItems={'center'} direction={'row'} sx={{ height: "26px", paddingLeft: "35px", }}
                        onClick={() => handleLinkClick()}>
                        {/* <ArrowFat scale={.65} /> */}
                        <KeyboardArrowRightRoundedIcon fontSize='small' sx={{ color: theme.palette.primary.main }} />
                        <Typography variant='body1' sx={{ fontWeight: 300, pl: 1, pt: .25 }}>
                            {smsi.name}
                        </Typography>
                    </Stack>
                </Link>
            </Stack>
        ))
    return (
        <div>
            <Stack
                sx={{ paddingLeft: "35px", color: "#192357", fontSize: '16px', backgroundColor: '#fff', }}>
                <Link passHref href={`/ortopedijos-gaminiai/${menuItem?.slug?.slug}/${submenuItem?.slug?.slug ?? ''}`}>
                    <Stack onClick={handleLinkClick}
                        sx={{
                            cursor: 'pointer', '&:hover': { textDecoration: 'underline' },
                        }}>
                        <Typography variant='subtitle1' fontWeight={400} lineHeight={1.4}
                            sx={{
                                color: theme.palette.primary.main, paddingRight: '20px',
                                pb: (renderSidemenuItems?.length ?? 0) > 1 ? 1 : 0
                            }}>
                            {submenuItem?.name}
                        </Typography>
                    </Stack>
                </Link>
            </Stack>

            {renderSidemenuItems}
        </div>
    )
}

export default SideSubmenuItem