import { BannerType, MenuItemType, PageType, PostType, ProductReviewType, ProductType } from "@idcms/store";
import { GetServerSideProps, NextPage } from "next";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import * as BannersService from '../app/api/banners/banners.service';
import * as MenuService from '../app/api/menus/menus.service';
import * as PagesService from '../app/api/pages/pages.service';
import * as PostService from '../app/api/posts/posts.service';
import * as ReviewsService from '../app/api/product-reviews/productReviews.service';
import * as ProductsService from '../app/api/products/products.service';
import * as MySQLConnector from '../app/api/utils/mysql.connector';
import Layout from "../components/layout/Layout";
import HomeComponent from "../components/pages/home/HomeComponent";
import PageHead from "../components/shared/PageHead";

type Props = {
  page: PageType | null;
  mapsApiKey: string;
  posts: PostType[] | undefined;
  informationMenuItems?: MenuItemType[];
  newProducts?: ProductType[]
  banners?: BannerType[];
  reviews?: ProductReviewType[];
  menuItems?: MenuItemType[];
}

const HomePage: NextPage<Props> = ({ menuItems, reviews, banners, newProducts, page, posts, mapsApiKey }: Props) => {
  const sideMenuItems = menuItems?.filter((mi) => mi.menuName === 'Sidemenu')
  const footerMenuItems = menuItems?.filter((mi) => mi.menuName === 'Footer')
  const footerSecondMenuItems = menuItems?.filter((mi) => mi.menuName === 'Footer second')
  const servicesMenuItems = menuItems?.filter((mi) => mi.menuName === 'Services')
  const informationMenuItems = menuItems?.filter((mi) => mi.menuName === 'Information')
  return (
    <>
      <PageHead page={page} />
      <Layout servicesMenuItems={servicesMenuItems} sideMenuItems={sideMenuItems} footerMenuItems={footerMenuItems}
        footerSecondMenuItems={footerSecondMenuItems}
        informationMenuItems={informationMenuItems}>
        <HomeComponent posts={posts} newProducts={newProducts}
          sideMenuItems={sideMenuItems}
          reviews={reviews}
          mapsApiKey={mapsApiKey} banners={banners} />
      </Layout>
    </>
  );
};

export default HomePage;
export const getServerSideProps: GetServerSideProps = async (context: any) => {

  MySQLConnector.init();
  const newProductsPromise = ProductsService.getNewProducts(context.locale)
  const bannersPromise = BannersService.getBanners(context.locale)
  const reviewsPromise = ReviewsService.getAllProductReviews(context.locale)
  const postsPromise = PostService.getPosts(context.locale)
  const pagePromise = PagesService.getPageByLayout(context.locale, 'home')
  const menuItemsPromise = MenuService.getMenus(context.locale, ['Sidemenu', 'Footer', 'Footer second', 'Services', 'Information'])

  const [menuItems, newProducts, banners, reviews, posts, page] =
    await Promise.all([menuItemsPromise, newProductsPromise, bannersPromise, reviewsPromise, postsPromise, pagePromise])

  MySQLConnector.end();


  return {
    props: {

      mapsApiKey: process.env.NEXT_PUBLIC_BING_MAPS_API_KEY,
      page: page,
      posts: posts,
      banners: banners ?? null,
      newProducts: newProducts ?? null,
      reviews: reviews,
      menuItems: menuItems ?? null,
      ...(await serverSideTranslations(context.locale, ['common', 'home', 'product', 'contacts', 'review']))
    }
  }
}
