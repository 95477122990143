import { yupResolver } from "@hookform/resolvers/yup";
import { CredentialsType } from "@idcms/store";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { signIn } from 'next-auth/react';
import { useTranslation } from "next-i18next";
import Image from "next/legacy/image";
import { useState } from 'react';
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import ErrorBox from "../errors/ErrorBox";
import { useRouter } from "next/router";
import FormLabel from "@mui/material/FormLabel";
import { getTheme } from "../../layout/Theme";
import Link from "next/link";

type Props = {
    checkout?: boolean;

    toggleDialog?: () => void;
    handleToggleResetPassword?: () => void;
    handleToggleRegister?: () => void;
}

export const LoginForm = ({ checkout, handleToggleResetPassword, handleToggleRegister, toggleDialog }: Props) => {
    const { t } = useTranslation('common')
    const router = useRouter()
    const { checkout: isCheckout } = router.query
    const credentialsSchema = yup.object({
        email: yup.string().email(`${t("form.validEmail", { ns: 'common' })}`).required(`${t("form.emailRequired", { ns: 'common' })}`),
        password: yup.string().required(`${t("form.passwordRequired", { ns: 'common' })}`),
    }).required()

    const { register, handleSubmit, reset, formState: { errors } } = useForm<CredentialsType>({
        resolver: yupResolver(credentialsSchema),
    })

    const [error, setError] = useState<string | undefined>()
    const [loading, setLoading] = useState(false)
    const submit: SubmitHandler<CredentialsType> = async (
        credentials: CredentialsType
    ) => {
        setError(undefined)
        setLoading(true)

        await signIn('credentials', {
            redirect: false,
            callbackUrl: ((isCheckout ?? checkout) ? "/uzsakymas" : "/"),
            email: credentials.email,
            password: credentials.password
        }).then((data) => {
            if (data?.error) {
                setError(data?.error)
            } else {
                setError(undefined)
                reset()
                toggleDialog && toggleDialog()
                !toggleDialog && router.push((isCheckout ?? checkout) ? "/uzsakymas" : "/");
            }
        }).catch((err) => {
            console.log(err, "err");
        })

        setLoading(false)
    }


    const socialLoginHandler = async (provider: string) => {
        setError(undefined)
        setLoading(true)

        await signIn(provider,
            {
                redirect: false,
                callbackUrl: ((isCheckout ?? checkout) ? "/uzsakymas" : "/"),
            }).then((data) => {
                if (data?.error) {
                    setError(data?.error)
                } else {
                    setError(undefined)
                    router.push("/");
                    reset()
                }
            }).catch((err) => {
                console.log(err, "err");
            })

        setLoading(false)

    }
    const theme = getTheme()
    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack spacing={1} textAlign={'left'} pb={2} mb={1}
                sx={{ borderBottom: '1px solid #EFEFEF' }}>
                {/* <Typography mb={0} fontWeight={500} fontSize={'14px'} color={'#192357'}>
                    {t('header.auth.alreadyRegistered')}
                </Typography> */}
                <Stack spacing={1.5} pb={1}>
                    <TextField
                        label={<Typography component={FormLabel} required variant='body2'
                            color={!!errors?.email?.message ? 'error' : theme.palette.primary.light}   >
                            {t('header.auth.email')}
                        </Typography>}
                        size='small' fullWidth
                        {...register(`email`)}
                        error={!!errors?.email?.message}
                        helperText={errors?.email?.message}
                    />
                    <TextField
                        label={<Typography component={FormLabel} required variant='body2'
                            color={!!errors?.password?.message ? 'error' : theme.palette.primary.light}   >
                            {t('header.auth.password')}
                        </Typography>}
                        size='small' fullWidth
                        {...register(`password`)}
                        error={!!errors?.password?.message}
                        helperText={errors?.password?.message}
                        type="password"
                    />
                </Stack>
                <LoadingButton loading={loading} variant='contained' sx={{ marginX: 4, height: '35px', fontSize: '14px' }}
                    type="submit">
                    {t('header.auth.login').toUpperCase()}
                </LoadingButton>
                <Button variant='outlined' onClick={() => socialLoginHandler('google')}
                    sx={{ marginX: 4, height: '35px', fontSize: '14px', marginBottom: 1, textTransform: 'none' }}
                    endIcon={<Image layout='fixed' width={20} height={20} src={'/assets/images/google-icon.png'} alt={''} />}>
                    {t('header.auth.loginWith')}
                </Button>
                {error && <ErrorBox errors={error} />}

                {handleToggleResetPassword && <Typography fontWeight={500} fontSize={'12px'} maxWidth={'fit-content'} color={'#192357'} sx={{ cursor: 'pointer' }}
                    onClick={handleToggleResetPassword}>
                    {t('header.auth.forgotPassword')}
                </Typography>}
                {!handleToggleResetPassword &&
                    <Link passHref href={'/reset-password'}>
                        <Typography fontWeight={500} fontSize={'12px'} maxWidth={'fit-content'} color={'#192357'} sx={{ cursor: 'pointer' }}
                            onClick={handleToggleResetPassword}>
                            {t('header.auth.forgotPassword')}
                        </Typography>
                    </Link>}
                {isCheckout &&
                    <Stack>
                        <Typography variant="caption" textAlign={'center'} sx={{ pb: 1.5 }}>
                            {t('header.auth.or')}
                        </Typography>

                        <Link passHref href={'/uzsakymas'} style={{ width: '100%' }}>
                            <Button color="secondary" fullWidth variant='outlined' sx={{ height: '35px', fontSize: '14px' }}>
                                {t('header.auth.continueAsGuest').toUpperCase()}
                            </Button>
                        </Link>
                    </Stack>}

            </Stack>
            <Stack spacing={1} textAlign={'left'}>
                <Typography fontWeight={500} fontSize={'14px'} color={'#192357'} pt={1} mb={0}>
                    {t('header.auth.notRegistered')}
                </Typography>
                {handleToggleRegister &&
                    <Button color="secondary" fullWidth variant='contained' sx={{ height: '35px', fontSize: '14px', color: '#fff' }} onClick={handleToggleRegister}>
                        {t('header.auth.register').toUpperCase()}
                    </Button>}
                {!handleToggleRegister &&
                    <Link passHref href={'/registruotis'} style={{ width: '100%' }}>
                        <Button color="secondary" fullWidth variant='contained' sx={{ height: '35px', fontSize: '14px', color: '#fff' }}>
                            {t('header.auth.register').toUpperCase()}
                        </Button>
                    </Link>}

            </Stack>
        </form>
    )
}