import React from 'react'
type Props = {
    fillColor?: string;
    color?: string;
    scale?: number;
}
const SlideButtonNext = ({ fillColor, color, scale }: Props) => {
    return (
        <svg width="55" height="55" viewBox="0 0 55 55" style={{ transform: `scale(${scale ?? 1})` }}>

            <g id="Comments_slide_button" data-name="Comments slide button" transform="translate(46.249 43) rotate(180)" >

                <g transform="matrix(-1, 0, 0, -1, 46.25, 43)" filter="drop-shadow(0 0 3px rgb(0 0 0 / 0.15))" style={{ border: '1px solid red' }}>

                    <rect id="Rectangle_60-2" data-name="Rectangle 60" width="37" height="37" rx="5" transform="translate(9 6)" fill={fillColor ? fillColor : "#192357"} />
                </g>
                <path id="Path_345" data-name="Path 345" d="M-73.7,885l-7.714,8.6,7.714,8.261" transform="translate(95.45 -875.486)" fill="none" stroke={color ? color : "#fff"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </g>
        </svg>


    )
}

export default SlideButtonNext