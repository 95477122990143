import Typography from '@mui/material/Typography';
import React from 'react'

type Props = {
    title: string;
}

const SectionTitle = ({ title }: Props) => {
    return (
        <Typography variant='h5' component={'h3'}
            fontWeight={600} color='primary' textAlign={'center'} mt={2} mb={{ lg: 2, md: 2, sm: 2, xs: 1 }}
            sx={{
                borderBottom: '3px solid #192357',
                paddingBottom: .5,
                paddingX: '20px',
                mx: 'auto'
            }}>
            {title}
        </Typography>
    )
}

export default SectionTitle